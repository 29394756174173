import React from "react";

export const Team = (props) => {
  return (
    <div id="career" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Explore Opportunities</h2>
        </div>
        <p>Join us at Intellimation Market Services to empower top AI-driven enterprises
           on their digital transformation journey. Collaborate with like-minded professionals,
            elevate your career through continuous learning, and seize opportunities for growth
             and innovation. Freshers can engage in exclusive programs for diverse roles, ensuring
              every career thrives with the right support and advancement pathways toward the next
               achievement.</p>
        <p>Send your CV and cover letter to <a href="mailto:dharaa.patel@imservice.in">dharaa.patel@imservice.in</a></p>
      </div>
    </div>
  );
};
